export const getLand = (context, { width, height }) => {
  context.lineWidth = "1";
  context.strokeStyle = "white";

  const lR = 100;
  const lG = 100;
  const lB = 140;
  let testPixelCount = 20;
  const land = [];
  const startLand = [];

  const pixel = context.getImageData(0, 1, width, height).data;

  for (let y = height - 1; y >= 0; y--) {
    // pixel[y * width * 4 + 2]
    const pR = pixel[y * width * 4];
    const pG = pixel[y * width * 4 + 1];
    const pB = pixel[y * width * 4 + 2];
    if (pB > lB) {
      startLand.unshift(y);
      testPixelCount--;
    }
    if (testPixelCount === 0) break;
  }

  let topLandY = startLand[0];
  land[0] = topLandY;

  for (let x = 1; x < width; x++) {
    // let stop = false;
    let isLand = false;
    let isSky = false;
    let findY = 0;
    // console.log("x", x, "y", topLandY);
    while (true) {
      // const pR = pixel[(topLandY + findY) * width * 4 + x * 4];
      // const pG = pixel[(topLandY + findY) * width * 4 + x * 4 + 1];
      const pB = pixel[(topLandY + findY) * width * 4 + x * 4 + 2];

      if (findY + topLandY > height || findY + topLandY < 1) {
        break;
      }

      if (pB < lB) {
        //Земля
        isLand = true;
        if (isSky) {
          topLandY = topLandY + findY - 1;
          land[x] = topLandY;
          break;
        }
        findY--;
      } else {
        //Небо
        isSky = true;
        if (isLand) {
          topLandY = topLandY + findY;
          land[x] = topLandY;
          break;
        }
        findY++;
      }
    }
  }

  for (var i = 0; i < land.length - 1; i++) {
    context.beginPath();
    context.lineWidth = 1;
    context.moveTo(i, land[i]);
    context.lineTo(i + 1, land[i + 1]);
    context.closePath();
    context.stroke();
  }

  return land;
};
