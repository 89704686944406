import React, { useRef, memo, useCallback } from "react";
import { useSettingsContext } from "./context";

import "./style.css";

const Settings = ({ onClose, open }) => {
  const { blueColor, setBlueColor } = useSettingsContext();
  const BfRef = useRef(0);

  const onBFChange = useCallback(() => {
    setBlueColor(BfRef.current.value);
  }, [setBlueColor]);

  if (!open) return;
  return (
    <div className="settings">
      <div className="settingsBlock">
        <div className="closeBtn" onClick={onClose}>
          &times;
        </div>
        <div className="setLine">
          Синий
          <input
            ref={BfRef}
            onChange={onBFChange}
            type="range"
            min={0}
            max={255}
            value={blueColor}
          />
          <div className="colNum">{blueColor}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(Settings);
