import { getLand } from "./getLand";
export const onScan = (
  context,
  size,
  step = 3,
  onFinish,
  setLoader,
  blueColor = 140
) => {
  if (!size?.width || !size?.height) return;

  const land = getLand(context, size);

  const sizeW = 10;
  const sizeH = 10;

  //   const w = 2400;
  //   const h = 700;

  const w = size.width;
  const h = size.height;

  // Количество ячеек
  const countW = Math.ceil(w / sizeW);
  const countH = Math.ceil(h / sizeH);

  const areasCount = countW * countH;
  // let areasCount = 0;
  let objList = [];

  if (!window.Worker) return;
  const myWorker = new Worker(new URL("./worker.js", import.meta.url));

  for (let j = 0; j < countH; j++) {
    for (let i = 0; i < countW; i++) {
      const pixel = context.getImageData(
        sizeW * i,
        sizeH * j,
        sizeW,
        sizeH
      ).data;

      myWorker.postMessage({
        pixel,
        cubW: sizeW,
        cubH: sizeH,
        step,
        i,
        j,
        land,
        blueColor,
      });
    }
  }
  let count = 0;
  myWorker.onmessage = (e) => {
    if (e.data.length > 0) {
      objList = [...objList, ...e.data];
    }
    count++;
    setLoader(count / areasCount);
    if (count === areasCount) {
      onFinish(objList);
    }
  };
};
