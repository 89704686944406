/** 
  recs [
    l, t, w, h
  ]
*/

// const colors = ["red", "green", "blue", "orange", "black", "yellow"];

export const drawRects = (context, objList, total) => {
  if (!objList) return;
  if (objList.length === 0) return;

  context.lineWidth = "1";
  context.strokeStyle = "red";
  objList.map(({ x, y }) => {
    context.strokeRect(x, y, 2, 2);
  });
};
