import { useState, useEffect, useCallback, useRef } from "react";
import { onScan } from "./func/scan";
import { drawRects } from "./func/drawRects";
import cn from "classnames";
import Settings from "./components/Settings";
import { useSettingsContext } from "./components/Settings/context";
import "./App.css";

// 4032 x 3024 = 12192768
// 640 x 480 = 307200
// 40

const FindObj = () => {
  const [mode, setMode] = useState("watching");
  const [settingsModal, setSettingsModal] = useState(false);
  const [size, setSize] = useState({});
  const [freeImg, setFreeImg] = useState(false);
  const [loading, setLoading] = useState(0);
  const { blueColor } = useSettingsContext();
  const canvasRef = useRef(0);
  const canvasRefFree = useRef(0);
  // const canvasVideoRef = useRef(0);
  const videoRef = useRef(null);
  const step = 1;
  useEffect(() => {
    const video = videoRef.current;
    // const video = document.createElement("video");
    // video.ref = videoRef;
    if (!video) return;
    if (navigator?.mediaDevices?.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "environment",
            width: { ideal: 3024 },
            height: { ideal: 4032 },
          },
          audio: false,
        })
        .then(function (stm) {
          video.srcObject = stm;
          video.play();
          let { width, height } = stm.getTracks()[0].getSettings();
          setSize({ width, height });
        });
    }
  }, []);

  const onScanClick = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const canvasFree = canvasRefFree.current;
    if (!video || !canvas) return;

    setMode("scaning");
    const { width: videoWidth, height: videoHeight } = size;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    canvasFree.width = videoWidth;
    canvasFree.height = videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0);
    const ctxFree = canvasFree.getContext("2d");
    ctxFree.drawImage(video, 0, 0);
    video.pause();

    onScan(
      ctx,
      {
        width: videoWidth,
        height: videoHeight,
      },
      step,
      (objList) => {
        drawRects(ctx, objList);
        setMode("scaned");
      },
      (d) => {
        setLoading(d);
      },
      blueColor
    );
  }, [size, blueColor]);

  const onRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const onChangeCanvase = useCallback(() => {
    setFreeImg(!freeImg);
  }, [freeImg]);

  const onTest = useCallback(() => {
    const imgPath = "./c4.png";
    const img = new Image();
    img.src = imgPath;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const canvasFree = canvasRefFree.current;
    const ctxFree = canvasFree.getContext("2d");
    img.onload = function () {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      canvasFree.width = img.naturalWidth;
      canvasFree.height = img.naturalHeight;
      ctx.drawImage(img, 0, 0);
      ctxFree.drawImage(img, 0, 0);
      setMode("scaning");

      onScan(
        ctx,
        {
          width: img.naturalWidth,
          height: img.naturalHeight,
        },
        step,
        (objList) => {
          drawRects(ctx, objList);
          setMode("scaned");
        },
        (d) => {
          setLoading(d);
        },
        blueColor
      );
    };
  }, [blueColor]);

  const onSettings = useCallback(() => {
    setSettingsModal(true);
  }, []);
  const onCloseSetting = useCallback(() => {
    setSettingsModal(false);
  }, []);

  return (
    <>
      <div className="body">
        {/* <canvas
          className={cn("canvasVideo", { hide: freeImg })}
          ref={canvasVideoRef}></canvas> */}
        <video
          ref={videoRef}
          controls={true}
          muted
          autoPlay
          className={cn("video", { hide: mode !== "watching" })}></video>
        {mode === "scaning" ? (
          <div className="scanBlock">
            <div className="scanModal">
              <div>Идет сканирование</div>
              <div>{(loading * 100).toFixed(0)}%</div>
              <progress max="1" value={loading} />
            </div>
          </div>
        ) : null}

        <canvas
          className={cn("canvas", { hide: freeImg })}
          ref={canvasRef}></canvas>
        <canvas
          className={cn("canvas", { hide: !freeImg })}
          ref={canvasRefFree}></canvas>
      </div>
      <footer className="footer">
        <div className="settingBtn" onClick={onSettings}>
          &#128736;
        </div>
        <div className="footerBtn">
          {mode === "scaned" ? (
            <>
              <div>
                <button className="btn" onClick={onChangeCanvase}>
                  {freeImg ? "Показать метки" : "Убрать метки"}
                </button>
              </div>
              <div>
                <button className="btn" onClick={onRefresh}>
                  Сбросить
                </button>
              </div>

              {/* <div>
              {size.width}/{size.height}
            </div> */}
            </>
          ) : null}
          {mode === "watching" ? (
            <>
              <button className="btn" onClick={onScanClick}>
                Сканировать небо
              </button>
              {/* <button className="btn" onClick={onTest}>
                onTest
              </button>*/}
            </>
          ) : null}
        </div>
      </footer>
      {settingsModal && (
        <Settings onClose={onCloseSetting} open={settingsModal} />
      )}
    </>
  );
};

export default FindObj;

/*
type TFindedObj = {
  x: number;
  y: number;
};
[
  obj: 1
  coords: [
    {x,y}
    {x,y}
  ],
  obj: 2, 
  coords:[
    {x,y}
    {x,y}
  ]
]

*/
