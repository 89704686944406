import React, { memo, useContext, createContext, useState } from "react";

const SettingsContext = createContext({
  blueColor: 140,
  setBlueColor: () => {},
});

export const useSettingsContext = () => useContext(SettingsContext);

const SettingsProvider = ({ children, blueColor: bc }) => {
  const [blueColor, setbF1] = useState(bc);

  const setBlueColor = (d) => {
    setbF1(d);
    // console.log(d);
  };

  return (
    <SettingsContext.Provider
      value={{
        blueColor,
        setBlueColor,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default memo(SettingsProvider);
